import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { 
    StyledContainer as Container,
    StyledTitle as Title,
    StyledLogo as Logo,
    StyledLink as Link 
} from './header.css';
import Nav from 'components/header/nav';


// Example of a component-specific page transition
const AnimatedContainer = posed.div({
    enter: {
        y: 0,
        transition: {
            ease: 'easeInOut',
        },
    },
    exit: {
        y: '-100%',
        transition: {
            ease: 'easeInOut',
        },
    },
});

const Header = ({ title }) => (
    <AnimatedContainer>
        <Container>
            <Link to="/">
                <Container>
                    <Logo />
                    <Title as="h1" displayType="inline" size="large">
                        {title}
                    </Title>
                </Container>
            </Link>

            <Nav />
        </Container>
    </AnimatedContainer>
);

Header.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Header;
