import styled from "styled-components";
import { Link } from "gatsby";
import Title from "components/title";
import CocktailLogo from "images/logo_cocktail_image.svg";
import MEDIA from "helpers/mediaTemplates";

export const StyledContainer = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;

	${MEDIA.TABLET`
		font-size: 1.5rem;
	`};

  a {
	color: #757575;
	transition: color 0.2s ease;
	text-decoration: none;

	&:hover {
	  color: inherit;
	}
  }
`;

export const StyledLogo = styled(CocktailLogo)`
  display: inline;
  width: 50px;
  padding-right: 1rem;
`;

export const StyledLink = styled(Link)`
  :hover {
	text-decoration: none;

	svg {
	  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	  transform: translate3d(0, 0, 0);
	  backface-visibility: hidden;
	  perspective: 1000px;
	}
  }

  @keyframes shake {
	10%,
	90% {
	  transform: translate3d(-0.5px, 0, 0);
	}

	20%,
	80% {
	  transform: translate3d(0.5px, 0, 0);
	}

	30%,
	50%,
	70% {
	  transform: translate3d(-2px, 0, 0);
	}

	40%,
	60% {
	  transform: translate3d(2px, 0, 0);
	}
  }
`;

export const StyledTitle = styled(Title)`
	padding-top: 10px;
	font-family: 'Tenor Sans', sans-serif;
	font-size: 2rem;

	${MEDIA.TABLET`
		font-size: 1.5rem;
	`};
`;
